 <div class="popup-box-container">
      <div class="sessionWindow">     
            <h2 class="popupHeading">{{heading}}</h2>
            <h5 class="popupDesc">{{message}}</h5> 
            <h2 class="timer" *ngIf="showTimer">
    {{ (minutesDisplay) }}:{{ (secondsDisplay) && (secondsDisplay <=59) ? secondsDisplay : '00' }}</h2>
          </div>

        <div class="actionButton"> 
           
            <button  class="accept" (click)="accept()">{{acceptLabel}}</button>
            <button  class="reject" (click)="reject()">{{rejectLabel}}</button>
        </div>   
        
    </div> 

