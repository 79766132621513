import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpRequestInterceptorModule } from './core/interceptors/request-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogModule } from './shared/components/dialog-box/dialog.module';
import { DialogService } from './shared/components/dialog-box/dialog.service';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { PipesModule } from './core/pipes/pipes.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DatePipe } from '@angular/common';
import { MaterialModule } from './core/material/material.module';
import { AuthenticationService } from './authentication/authentication.service';
import { ImageUrl } from './shared/constants/image--url-constant';
import { ConfirmDialogBoxComponent } from './shared/components/confirm-dialog-box/confirm-dialog-box.component';
import { initializeApp } from "firebase/app";
import { NotificationCenterModule } from '@novu/notification-center-angular';

export function appInitializerFn(authService: AuthenticationService) {
  const orgId = JSON.parse(atob(localStorage.getItem('sessionToken').split('.')[1])).orgId;      

  const data :any = authService.getAppInfoByOrgId(orgId).toPromise().then(data=>{
  if (data) {
    
    localStorage.setItem('primaryAppColour', data.organizationUnitExtdRWS.primaryAppColour);
    localStorage.setItem('secondaryAppColour', data.organizationUnitExtdRWS.secondaryAppColour)
    
    document.documentElement.style.setProperty('--primary-color', data.organizationUnitExtdRWS.primaryAppColour);
    document.documentElement.style.setProperty('--secondary-color', data.organizationUnitExtdRWS.secondaryAppColour);
   
    this.getImagePath = ImageUrl.IMAGES + data.organizationUnitExtdRWS.logoPath;
    localStorage.setItem('appLogo',this.getImagePath)

    this.signinService();

  }});

}

const app = initializeApp(environment.firebaseConfig);
@NgModule({
  declarations: [
    AppComponent,
    ConfirmDialogBoxComponent,
  ],
  imports: [
    NotificationCenterModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpRequestInterceptorModule,
    BrowserAnimationsModule,
    NgHttpLoaderModule.forRoot(),
    PipesModule,
    MaterialModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MaterialModule
    ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
