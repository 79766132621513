import { NgModule } from '@angular/core';
import { TruncatePipe } from './truncate.pipe';
import { SafePipe } from './safe.pipe';
import { LabelPipe } from './label.pipe';
import { ReplacePipe } from './replace.pipe';
import { StripTagsPipe } from './stripTags.pipe';
import { HoursPipe } from './hours.pipe';
import { timeFilterPipe } from './timeFilter.pipe';

@NgModule({
    declarations: [
        TruncatePipe,
        SafePipe,
        LabelPipe,
        ReplacePipe,
        StripTagsPipe,
        HoursPipe,
        timeFilterPipe
    ],
    exports: [
        TruncatePipe,
        SafePipe,
        LabelPipe,
        ReplacePipe,
        StripTagsPipe,
        HoursPipe,
        timeFilterPipe
    ]
})
export class PipesModule { }