import { Injectable, NgModule } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { ValidationService } from '../../shared/components/form-control/validation.service';

@Injectable({
    providedIn: 'root'
})

export class HttpRequestInterceptor implements HttpInterceptor {

    constructor(
        public router: Router,
        private readonly notificationService: NotificationService,
        private readonly autheticationService: AuthenticationService,
        private readonly validationService: ValidationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.notificationService.close();

        const sessionToken = localStorage.getItem('sessionToken');
        request = request.clone({
            headers: request.headers.set('X-Authorization', 'Bearer ' + sessionToken)
                .set('Content-Type', 'application/json')
        });

        return next.handle(request).pipe(
            tap(
                (response: HttpEvent<any>) => { },
                (error: any) => {
                    if (error instanceof HttpErrorResponse) {

                        if(error.status === 401){
                            this.autheticationService.signout().subscribe();
                        }else if (error.status === 500) {
                            if(error.error.applicationMessageCode == 'U2057'){
                                error.error.applicationMessage = ' We’re sorry, but your account has been deleted. For security reasons, we maintain strict policies regarding account access. If you believe this is an error or if you require assistance, please contact our support team at 0800 0845 113. Thank you for your understanding.'
                            }else{
                                error.error.applicationMessage = 'Sorry, there is a problem with the service';
                            }
                        }else if (error.status === 0) {
                            error.error.applicationMessage = 'Please check your internet connection';
                        }else if (error.status === 422 ) {
                            this.validationService.setFormServerErrors(error.error.errors);
                        }else if(error.status === 409){
                            // 409 should be handled at component level
                            return;
                        }else{
                            this.notificationService.error([error.error.applicationMessage || error.error.errorMessage], false);
                        }
                    }
                }
            )
        );
    }
}

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        }
    ]
})

export class HttpRequestInterceptorModule { }
