import { Component, Inject, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, timer } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { UserActivityService } from 'src/app/home/user-activity/user-activity.service';
import { take } from 'rxjs/operators';
import { Confirmation } from '../confirm-box/confirm-box.interface';


@Component({
  selector: 'vc-confirm-dialog-box',
  templateUrl: './confirm-dialog-box.component.html',
  styleUrls: ['./confirm-dialog-box.component.scss']
})
export class ConfirmDialogBoxComponent {

  

  private timerSubscription: Subscription;
  countdownSubscription: Subscription;

  minutesDisplay = 0;
  secondsDisplay = 0;
  endTime =  2 * 60;

  heading
  message
  showTimer
  countdown
  acceptLabel
  rejectLabel
  
  

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly matDialog : MatDialog,
    private readonly authenticationService : AuthenticationService,
    private readonly dialogRef : MatDialogRef<ConfirmDialogBoxComponent>,
    private readonly userActivityService :UserActivityService,
    private readonly renderer: Renderer2,

  ) {

   
  }

  ngOnInit(){
    console.log(this.data,'data');
    this.heading = this.data.header
    this.message = this.data.message
    this.showTimer = this.data.showTimer
    this.countdown = this.data.countdown
    this.acceptLabel = this.data.acceptLabel
    this.rejectLabel = this.data.rejectLabel  

    if (this.countdown) {
      this.startCountdown(this.countdown);
    } 
  }


  accept(){
    this.dialogRef.close('accept');
  }

    reject(){
     this.dialogRef.close('reject');
   }

  startCountdown(endTime: number = this.endTime) {
    const interval = 1000;
    const duration = endTime;
    this.countdownSubscription = timer(0, interval)
      .pipe(
        take(duration)
      )
      .subscribe(
        value => {
          this.render((duration - +value) * interval);
        },
        err => { },
        () => {
          this.countdownSubscription.unsubscribe();
          this.reject();
        }
      );
      this.userActivityService.lastActivity = new Date().getTime();
  }

  private render(count) {
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);
  }

  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }
}
